:root {
  --nav-bar-height: min(21.026vmin, 112px);
  --nav-bar-logo-max-width: min(28.5024svh, 236px);
  --nav-bar-logo-small-max-width: min(18.128svh, 153px);
  --right-menu-width: min(31.39svh, 339px);
  --button-font-size: max(min(1.224svw, 23.5px), 15px);
  --page-width: 68.386svw;
  --page-right: calc(50% - var(--page-width) / 2);
  --input-border-radius: 20px;
}

.center-page {
  position: fixed;
  top: 50%;
  width: 100%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.container {
  min-width: 90%;
}

.container-max {
  min-width: 100%;
}

.nav-bar {
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--nav-bar-height);
  background-color: white;
  z-index: 999;
}

.right-menu {
  width: var(--right-menu-width);
  height: 100%;
  display: inline-block;
}

.right-side-nav {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #44403e;
  overflow-x: hidden;
  padding-top: 60px;
  text-align: center;
}

.right-side-nav-menu {
  position: inherit;
  top: 20%;
  list-style: none;
  width: var(--right-menu-width);
}

.right-side-nav-menu a {
  text-decoration: none;
  font-size: 25px;
  color: #ffffff;
  list-style: none;
}

.right-side-nav-menu a:hover {
  color: #f1f1f1;
}

.right-side-nav-menu .clicked {
  color: #fdb833 !important;
}

.right-side-nav-menu .link {
  padding-right: 15%;
  width: calc(var(--right-menu-width) - 15%);
}

.right-side-nav-menu .item {
  border-bottom: 4px solid #3a3838;
}

.page-body {
  position: relative;
  top: var(--nav-bar-height);
  font-family: "Rubik-Regular";
}

.page-content-with-right-menu {
  margin-right: var(--right-menu-width) !important;
  width: 100% - var(--right-menu-width) !important;
}

.nav-bar-logo {
  max-width: var(--nav-bar-logo-max-width);
  max-height: 6.7287vmin;
  height: auto;
  position: relative;
  top: 30%;
  right: calc(50% - var(--nav-bar-logo-max-width) / 2.5);
}

.nav-bar-logo-small {
  max-width: var(--nav-bar-logo-small-max-width);
  height: auto;
  position: relative;
  top: 25%;
  right: calc(50% - var(--nav-bar-logo-small-max-width) / 2);
}

.big-nav-login-container {
  position: relative;
  float: left;
  width: calc(100% - var(--right-menu-width));
  height: 100%;
  box-shadow:
    1px 1px 5px #dfd9d9,
    -1px -1px 5px #dfd9d9;
}

.big-nav-login {
  position: relative;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  left: 142px;
}

.big-nav-account-label {
  position: relative;
  left: 10px;
  font-size: 30px;
  font-family: "Rubik-Regular";
}

.navbar-hamburger-icon {
  position: absolute;
  height: 50px;
  width: 50px;
  top: 25%;
  right: 15px;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(44, 191, 201,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  background-color: #2cbfc9;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  text-align: center;
}

.sidenav ul {
  list-style: none;
  text-align: right;
}

.sidenav a {
  text-decoration: none;
  font-size: 25px;
  color: #ffffff;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .clicked {
  color: #fdb833 !important;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.sidenav .logo {
  position: absolute;
  top: 15px;
  left: 25px;
  font-size: 36px;
}

.sidenav .buttons {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
}

.sidenav button {
  margin: auto;
  margin-top: 8px;
}

.woman-photo {
  max-width: 100%;
  width: 1644px;
  height: auto;
  position: relative;
  left: -13px;
}

.text-home {
  font-family: "Rubik-Bold";
  font-size: min(5.21svw, 100px);
  line-height: min(4.69svw, 90px);
  letter-spacing: max(-0.312svw, -6px);
  margin-right: min(16.93svw, 325px);
  margin-top: auto !important;
}

.text-home-title {
  max-width: 560px !important;
  transform: matrix(1, 0, 0, 1, 0, 0);
  color: #2cbfc9;
}

.text-home-sub-title {
  max-width: 560px !important;
  transform: matrix(1, 0, 0, 1, 0, 0);
  color: #fdb833;
}

.text-home-label {
  max-width: 601px !important;
  font-family: "Rubik-Light";
  transform: matrix(1, 0, 0, 1, 0, 0);
  color: #000000;
  font-size: min(1.563svw, 30px);
  line-height: min(2.1svw, 40.33px);
  letter-spacing: max(-0.0625svw, -1.2px);
}

.logout-button {
  position: inherit;
  bottom: 2%;
  width: var(--right-menu-width);
  display: flex;
  justify-content: center;
}

.page-title {
  position: relative;
  width: 100%;
  text-align: center;
  background-color: #fdb833;
  color: white;
  font-size: min(6svw, 30px);
}

.page-action-bar {
  position: relative;
  min-height: 8svh;
  width: var(--page-width);
  right: var(--page-right);
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.page-action-bar .buttons {
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
}

.page-action-bar button {
  margin-left: 0px;
  margin-right: 5px;
  margin-bottom: 0px;
  font-size: medium;
}

.page-action-bar .left-buttons {
  margin-right: auto;
  min-width: 10.308svh;
}

.data-div {
  position: relative;
  min-height: min(58.241svh, 629px);
  width: var(--page-width);
  right: var(--page-right);
  text-align: center;
  background: transparent url("./resources/Rectangle 177.png");
  border-radius: 10px;
}

.data-div-title {
  position: relative;
  font-family: "Rubik-Regular";
  font-size: min(5svw, 30px);
  color: #393c46;
  top: min(13.8svh, 148px);
}

.data-div-sub-title {
  position: relative;
  font-family: "Rubik-Regular";
  font-size: min(3svw, 18px);
  color: #adadad;
  top: min(12.778svh, 138px);
}

.data-div-add-button {
  position: relative;
  top: min(12.778svh, 138px);
  max-width: min(9.4444svh, 102px);
}

.editor-buttons {
  position: absolute;
  bottom: 25px;
}

.align-items-center {
  align-items: center !important;
  justify-content: center;
  --bs-gutter-x: 0px !important;
}

.table-page-table thead th {
  background-color: #2cbfc9;
  color: white;
}

.table-page-table td {
  color: #969696;
}

.table-page-filter-icon {
  width: 1.52svh;
  height: 1.52svh;
  position: relative;
  top: -2px;
}

.table-headline {
  background: transparent url("./resources/table-headline.png") 0% 0% no-repeat
    padding-box;
  position: relative;
  top: -10px;
  margin-right: 35px;
  width: 412px;
  height: 52px;
  margin-top: 15px;
}

.table-headline label {
  font-family: "Rubik-Regular";
  font-size: 30px;
  color: white;
  position: relative;
  top: 5px;
}

.col-padding {
  padding-left: 0px !important;
  padding-right: 7px !important;
}

.auto-complete-dropdown-item {
  width: 11svw !important;
  white-space: initial !important;
}

.clickable {
  cursor: pointer;
}

.table-sort-icon {
  position: relative;
  width: 1.52svh;
  height: 1.53svh;
  left: 1svw;
}

.table-filter-icon {
  width: 1.52svh;
  height: 1.52svh;
}

.table-filter-pick {
  padding: 8px 8px 8px 8px;
  text-decoration: none;
  font-size: 25px;
  transition: 0.3s;
}

input.search {
  background: url(./resources/search-icon.png) no-repeat right;
  background-size: 20px;
  background-color: white;
  background-position: calc(100% - 20px);
  padding-right: 45px;
}

input {
  border-radius: var(--input-border-radius) !important;
}

textarea {
  border-radius: var(--input-border-radius) !important;
}

.css-16xfy0z-control {
  border-radius: var(--input-border-radius) !important;
}

.css-13cymwt-control {
  border-radius: var(--input-border-radius) !important;
}

.css-t3ipsp-control {
  border-radius: var(--input-border-radius) !important;
}

.css-1nmdiq5-menu {
  border-radius: var(--input-border-radius) !important;
}

.css-1n6sfyn-MenuList {
  border-radius: var(--input-border-radius) !important;
}

.delete-icon {
  width: 2.038svh;
  height: 2.038svh;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-title {
  margin: auto;
}

.center-with-margin {
  max-width: 95%;
  margin: auto;
}

.align-right {
  text-align: right;
}

.label-margin-right {
  margin-right: 10px;
  margin-bottom: 0px !important;
}

.divider {
  border-bottom: 1px solid #3a3838;
}

.table-no-padding td {
  padding: 4px !important;
}

.table-header-bg thead th {
  background-color: #2cbfc9;
  color: white;
}

.margin-top-1 {
  margin-top: 15px;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.price-quote-summary-container {
  display: flex;
  justify-content: flex-end;
}

.price-quote-summary {
  width: 26svw;
  position: relative;
}

.modal-table {
  text-align: center;
}

.modal-table thead {
  color: #919191;
}

.table-wrap {
  --border: 2px;
  --border-color: white;
  --border-radius: 25px;

  border: var(--border) solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: white;
}

.table-wrap table {
  border-collapse: collapse;
  border-spacing: 0;
}

.table-wrap table td {
  border: var(--border) solid var(--border-color);
}

.table-wrap table td:first-child {
  border-left: none;
}

.table-wrap table td:last-child {
  border-right: none;
}

.table-wrap table tr:first-child td {
  border-top: none;
}

.table-wrap table tr:last-child td {
  border-bottom: none;
}

.table-wrap table tr:first-child td:first-child {
  border-top-left-radius: var(--border-radius);
}

.table-wrap table tr:first-child td:last-child {
  border-top-right-radius: var(--border-radius);
}

.table-wrap table tr:last-child td:first-child {
  border-bottom-left-radius: var(--border-radius);
}

.table-wrap table tr:last-child td:last-child {
  border-bottom-right-radius: var(--border-radius);
}

.accordion-button {
  background-color: white !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: black !important;
}

.rounded-accordion-body {
  border: 2px solid #e9e9e9;
  border-radius: 35px;
  padding: 15px 0px 15px 0px;
}

.photographer-price-quote-produce {
  position: relative;
  background: transparent url("./resources/Rectangle 177.png");
  border-radius: 10px;
  min-height: 65vh;
  width: 60vw;
  right: calc(50% - 60vw / 2);
  top: 10svh;
  font-size: var(--button-font-size) !important;
  font-family: "Rubik-Regular";
}

.react-multi-email > span[data-placeholder] {
  position: inherit !important;
  top: 0px !important;
}

table {
  page-break-inside: auto;
}

tr,
td {
  page-break-inside: avoid;
  page-break-after: auto;
}

thead {
  display: table-header-group;
}

.row {
  width: calc(100% - var(--bs-gutter-x));
}

.origin-row {
  width: calc(100% + var(--bs-gutter-x));
}

.text-end {
  text-align: end;
}

.checkbox {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.footer {
  bottom: 0;
  width: 100%;
}

.img-header {
  position: relative;
  left: -10px;
  top: 0;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: white;
}

.small-title {
  font-size: 15px;
  font-weight: bold;
}

.event-details-table {
  width: auto;
}

.event-details-table td {
  padding-left: 25px;
  padding-right: 25px;
}

.img-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.price-quotes-form-action-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* mobile adjustments */
@media screen and (max-width: 850px) {
  body {
    background-image: none !important;
  }

  .data-div {
    width: 100svw;
    right: calc(50% - 100svw / 2);
  }

  .page-action-bar {
    background-image: linear-gradient(
      rgb(238, 247, 249) 0%,
      rgb(238, 247, 249) 100%
    );
    width: 100svw;
    right: calc(50% - 100svw / 2);
  }

  .my-1 {
    width: 90% !important;
  }

  .auto-complete-dropdown-item {
    width: 88svw !important;
  }

  .table-page-table thead {
    font-size: 13px;
  }

  .page-action-bar .left-buttons {
    position: relative;
    left: 5px;
  }

  .price-quote-summary-container {
    display: block;
  }

  .price-quote-summary {
    width: auto;
    left: 0;
  }

  .photographer-price-quote-produce {
    min-height: 70svh;
    width: 100svw;
    right: calc(50% - 100svw / 2);
  }

  .editor-buttons {
    bottom: -110px;
  }

  .price-quotes-form-action-buttons button {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 5px;
    font-size: medium;
  }
}

@media print {
  body {
    background-image: none !important;
  }
  .table {
    font-size: 12px;
  }
  .footer {
    position: fixed;
  }
}
